import { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from './button'
import LocationFeature from './location_feature'

import styles from './location_row.module.css'

import { Networking } from '../helpers/networking'
import { Locations } from '../helpers/locations'
import { User } from '../helpers/user'
import { useNavigate } from 'react-router-dom'

export default function LocationRow({ data }) {
  const [user, setUser] = useState(false)
  const [speedtestsAvailable, setSpeedtestsAvailable] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (data !== null) {
      if (speedtestsAvailable === null) {
        Networking.speedtestsAvailable(data.location_uuid)
          .then((numberOfSpeedtestsAvailable) => {
            //setSpeedtestsAvailable(numberOfSpeedtestsAvailable);
          })
          .catch((e) => {
            setSpeedtestsAvailable(0)
          })
      }
    }
  })

  useEffect(() => {
    User.isLoggedIn().then((user) => {
      setUser(user)
    })
  }, [])

  // We're also rendering as a premium upgrade row
  if (!data) {
    // Wording and URL depend on whether or not the user is logged in
    var title = 'Log in'
    var subTitle = 'This place is only visible to our members'
    var buttonAction = () => {
      navigate(User.getLoginUrl())
    }
    if (user) {
      title = 'Upgrade'
      subTitle = 'This place is accessible for members with a higher community score.'
      buttonAction = () => {
        navigate('/account/plan')
      }
    }

    return (
      <div className={styles.premiumRowContainer}>
        <div className={`${styles.columnContainer} ${styles.locationTitle}`} style={{ width: 120 }}>
          <Button title={title} onClick={buttonAction} className={`umami--click--upgrade-button`} />
        </div>
        <div
          className={`${styles.columnContainer} ${styles.locationSubtitle}`}
          style={{ width: '100%', paddingTop: 0 }}>
          {subTitle}
        </div>
      </div>
    )
  }

  const renderLocationType = (locationType) => {
    const locationDetails = Locations.getDetails(locationType)
    return <FontAwesomeIcon icon={locationDetails.icon} size="1x" color={locationDetails.color} />
  }

  //let features = Locations.getFeatures(data);
  let features = []
  //  console.log(Locations.getFeatures(data));

  return (
    <a href={`/place/${data.location_uuid}`} style={{ width: '100%' }}>
      <div className={styles.rowContainer}>
        <div className={`${styles.columnContainer} ${styles.locationIcon}`}>
          {renderLocationType(data.location_type)}
        </div>
        <div className={`${styles.columnContainer} ${styles.locationTitle}`}>
          {data.location_title}
          <div
            className={styles.featureContainer}
            style={{ marginTop: features.length > 0 ? 10 : 0 }}>
            {features.map((feature, featureIndex) => {
              return (
                <LocationFeature
                  key={`feature_${featureIndex}`}
                  title={feature.short_title}
                  icon={feature.icon}
                  color={feature.color}
                />
              )
            })}
          </div>
        </div>
        {data.city_title && (
          <div className={`${styles.columnContainer} ${styles.cityTitle}`}>
            {data.city_title}, {data.country_title} &nbsp; {data.country_flag}
          </div>
        )}
      </div>
    </a>
  )
}
