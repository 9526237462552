import { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { User } from './helpers/user'

import Home from './pages/index'
import Explore from './pages/explore'
import PlacePage from './pages/place'
import LoginPage from './pages/login'
import RegisterPage from './pages/register'
import VerificationForwarder from './pages/verify'
import CountryPage from './pages/country'
import AccountPage from './pages/account'
import AddPlacePage from './pages/add'
import EditLocationPage from './pages/edit'

import Loading from './components/loading'
import Liked from './pages/liked'
import JoinUs from './pages/join'

const NeedsAuthentication = (props) => {
  return props.user ? props.children : <Navigate to="/login" />
}

const App = () => {
  const [userAvailable, setUser] = useState(null)

  useEffect(() => {
    User.isLoggedIn().then((user) => {
      setUser(user)
    })
  }, [])

  /* Let's figure out the authentication state ... */
  if (userAvailable === null) return <Loading page />

  // Okay, at this point, we should know if we have a user or not!
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/verify/:code" element={<VerificationForwarder />} />
      <Route
        path="/account/:tab"
        element={
          <NeedsAuthentication user={userAvailable}>
            <AccountPage />
          </NeedsAuthentication>
        }
      />
      <Route
        path="/add"
        element={
          <NeedsAuthentication user={userAvailable}>
            <AddPlacePage />
          </NeedsAuthentication>
        }
      />
      <Route
        path="/edit/:place_id"
        element={
          <NeedsAuthentication user={userAvailable}>
            <EditLocationPage />
          </NeedsAuthentication>
        }
      />
      <Route path="/explore/:country/:city" element={<Explore />} />
      <Route path="/place/:place_id" element={<PlacePage />} />
      <Route path="/country/:country" element={<CountryPage />} />
      <Route path="/liked" element={<Liked />} />
      <Route path="/join" element={<JoinUs />} />
    </Routes>
  )
}

export default App
