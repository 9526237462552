import axios from 'axios'
import { Auth, API } from 'aws-amplify'

export const Networking = {
  apiServer: () => {
    return process.env.NODE_ENV === 'production'
      ? 'https://api.lonelydesk.com'
      : 'http://localhost:9292'
  },

  apiGet: async (apiName, apiPath) => {
    try {
      const currentSession = await Auth.currentSession() // This throws if there is no user session
      return await API.get(apiName, apiPath, {
        headers: {
          Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`
        }
      })
    } catch (e) {
      // For unauthenticated calls, we're bypassing the Amplify toolkit.
      try {
        const response = await axios.get(`${Networking.apiServer()}/${apiName}/${apiPath}`)
        return response.data
      } catch (er) {
        console.log('AXIOS: Failed: ', er)
      }
    }
  },

  apiPost: async (apiName, apiPath, payload) => {
    try {
      const currentSession = await Auth.currentSession() // This throws if there is no user session
      return await API.post(apiName, apiPath, {
        body: payload,
        headers: {
          Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`
        }
      })
    } catch (e) {}
  },

  accountRegister: async (email, password) => {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password: password,
        attributes: { email: email }
      })
      console.log(user)
      return true
    } catch (e) {
      return false
    }
  },

  cognitoLogin: async (email, password) => {
    try {
      const user = await Auth.signIn(email, password)
      return {
        status: true,
        user: user
      }
    } catch (e) {
      console.log('Error', e)
      if (e.name === 'NotAuthorizedException') {
        return {
          status: false,
          error: 'not_authorized'
        }
      } else if (e.name === 'UserNotConfirmedException') {
        return {
          status: false,
          error: 'confirm_email'
        }
      } else {
        return {
          status: false,
          error: 'unknown_error'
        }
      }
    }
  },

  searchSuggestions: (txt, cityOnly = false) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Networking.apiServer()}/locations/search?q=${txt}${cityOnly ? '&city_only=true' : ''}`
        )
        .then((res) => {
          resolve(res.data.results)
        })
        .catch((error) => {
          console.log('Error: ', error)
          reject(error)
        })
    })
  },

  fetchFeaturedCities: async () => {
    const response = await Networking.apiGet('locations', 'featured')
    return response.status ? response : []
  },

  fetchCitiesForCountry: async (country_slug) => {
    return (await Networking.apiGet('locations', `country/${country_slug}`)).data || false
  },

  fetchLocationsForCity: async (country_slug, city_slug) => {
    const response = await Networking.apiGet('locations', `in/${country_slug}/${city_slug}`)
    return response.status ? response : []
  },

  fetchPublicLocations: async () => {
    return (await Networking.apiGet('locations', 'public')).data || []
  },

  speedtestsAvailable: async (location_uuid) => {
    return (await Networking.apiGet('speedtests', `${location_uuid}/available`)).data || []
  },

  fetchLocationDetails: async (location_uuid) => {
    const response = await Networking.apiGet('locations', `details/${location_uuid}`)
    return response.status ? response.data : false
  },

  fetchLocationFeatures: async (location_uuid) => {
    const response = await Networking.apiGet(
      'locations',
      `features/${location_uuid}?ac=${new Date().getTime()}`
    )
    return response.status ? response.data : []
  },

  fetchLocationComments: async (location_uuid) => {
    return (await Networking.apiGet('comments', `${location_uuid}`)).data || []
  },

  attemptLocationIdentification: async (location_uuid) => {
    const response = await Networking.apiGet('locations', `find/${location_uuid}`)
    return response.status ? response : false
  },

  fetchCityDetails: async (country_slug, city_slug) => {
    const response = await Networking.apiGet(
      'locations',
      `city/${country_slug}/${city_slug}/details`
    )
    return response.status ? response.results : false
  },

  fetchUserScore: async () => {
    const response = await Networking.apiGet('account', 'details')
    return response.status ? response.data : false
  },

  fetchSupportedLocations: async () => {
    const response = await Networking.apiGet('locations', `supported`)
    return response.status ? response.results : []
  },

  fetchSupportedTypes: async () => {
    const response = await Networking.apiGet('locations', `types`)
    return response.status ? response.results : []
  },

  addLocation: async (data) => {
    const response = await Networking.apiPost('locations', 'add', data)
    return response
  },

  updateLocationCoordinates: async (pid, lat, lng) => {
    await Networking.apiPost('locations', `update/${pid}/coordinates`, { lat: lat, lng: lng })
  },

  addFeature: async (data) => {
    await Networking.apiPost('locations', 'features/add', data)
  },

  featureFeedback: async (location_uuid, feature_uuid, feedback) => {
    await Networking.apiPost('locations', 'features/feedback', {
      location_uuid: location_uuid,
      feature_uuid: feature_uuid,
      feedback: feedback
    })
  },

  postSpeedMeasurement: async (location_uuid, data) => {
    await Networking.apiPost('speedtests', `${location_uuid}`, data)
  },

  removeFeature: async (data) => {
    await Networking.apiPost('locations', 'features/remove', data)
  },

  addLocationComment: async (data) => {
    await Networking.apiPost('comments', '', data)
  },

  toggleLocationLike: async (location_uuid) => {
    await Networking.apiPost('locations', `like/${location_uuid}`)
  },

  doesUserLikeLocation: async (location_uuid) => {
    const response = await Networking.apiGet('locations', `like/${location_uuid}`)
    return response.status
  },

  likedLocations: async () => {
    const response = await Networking.apiGet('account', `likes`)
    return response.status ? response.results : []
  },

  fetchSitemap: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Networking.apiServer()}/sitemap`)
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data)
          } else {
            reject('unknown_error')
          }
        })
        .catch((error) => {
          console.log('Error: ', error)
          reject(error)
        })
    })
  }
}
