import { useState, useEffect } from 'react'

import Typewriter from 'typewriter-effect'
import { Networking } from '../helpers/networking'
import { DebounceInput } from 'react-debounce-input'

import styles from './home_hero.module.css'

export default function HomeHero() {
  const [searchSuggestions, setSearchSuggestions] = useState([])
  const [searchField, setSearchField] = useState('')
  const [timerIsActive] = useState(true)
  const [placerholderCity, setPlaceholderCity] = useState('Vienna')
  const [categorySelection, setCategory] = useState('')

  useEffect(() => {
    const citySuggestions = ['Paris', 'Vienna', 'Amsterdam', 'Athens', 'London', 'Munich']
    let interval = null
    if (timerIsActive) {
      interval = setInterval(() => {
        setPlaceholderCity(citySuggestions[Math.floor(Math.random() * citySuggestions.length)])
      }, 5000)
    } else if (!timerIsActive) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timerIsActive])

  const updateSearch = (q) => {
    setSearchField(q)
    if (q.length < 3) {
      setSearchSuggestions([])
    } else {
      Networking.searchSuggestions(q).then((data) => {
        setSearchSuggestions(data)
      })
    }
  }

  const launchExplorer = (item) => {
    if (item.type === 'city') {
      let slug = item.city_slug.replace('_', '/')
      document.location.href = `/explore/${slug}${
        categorySelection ? '?c=' + categorySelection : ''
      }`
    } else {
      let slug = item.country_slug
      document.location.href = `/country/${slug}`
    }
  }

  return (
    <div className={styles.homeHero}>
      {/* 
      <div className={styles.typewriter}>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .changeDelay(50)
              .typeString('I NEED FAST WI-FI.')
              .pauseFor(2000)
              .deleteChars(11)
              .typeString('A GREAT VIEW')
              .pauseFor(1000)
              .deleteChars(12)
              .typeString("A RELIABLE PLACE TO WORK")
              .pauseFor(800)
              .typeString(".")
              .callFunction((state) => {
                state.elements.cursor.style.animation = 'none';
                state.elements.cursor.style.display = 'none';
              })
              .start()
          }}
        />
      </div>
      */}

      <div className={styles.explainer}>
        <div className={styles.explainerTitle}>Find a Reliable Space to Work</div>
        <div>Curated by a community of professional remote workers</div>
      </div>

      <div className={styles.gettingStarted}>
        {/*
        <div className={styles.categoryRadios}>
          <div>
            <input name="category" id="any" type="radio" checked />
            <label for="any">Anything</label>
          </div>
          <div>
            <input name="category" id="cafe" type="radio" />
            <label for="cafe">Cafe</label>
          </div>
          <div>
            <input name="category" id="airbnbs" type="radio" />
            <label for="airbnbs">AirBnb</label>
          </div>
          <div>
            <input name="category" id="hotel" type="radio" />
            <label for="hotel">Hotel</label>
          </div>
          <div>
            <input name="category" id="cowork" type="radio" />
            <label for="cowork">Co-Working</label>
          </div>
        </div>
    */}

        <div className={styles.inputForm}>
          <div className={styles.categorySelectorWrapper}>
            <select
              className={styles.categorySelector}
              value={categorySelection}
              onChange={(e) => {
                setCategory(e.target.value)
              }}>
              <option value="">&nbsp; Anything &nbsp;</option>
              <option value="957ae8c8-9eea-4575-b11b-7f583e956eb7">&nbsp; Airbnbs &nbsp;</option>
              <option value="df70cdb7-5fb0-40df-9511-e30fd417cb3c">&nbsp; Cafes &nbsp;</option>
              <option value="5ef024db-347c-4a31-9d6e-3d7abcf8c2e2">&nbsp; Co-Working &nbsp;</option>
              <option value="99a3450b-b66f-4526-bfc1-213b7e6d697a">&nbsp; Hotels &nbsp;</option>
            </select>
          </div>
          <span style={{ marginRight: 10 }}>in</span>
          <DebounceInput
            className={styles.inputField}
            minLength={2}
            autoFocus={true}
            placeholder={placerholderCity}
            debounceTimeout={300}
            value={searchField}
            onChange={(e) => {
              updateSearch(e.target.value)
            }}
          />
          <span style={{ fontSize: 16, marginLeft: 10, marginRight: 5, color: '#999' }}>
            or just
          </span>
          <span
            style={{ cursor: 'pointer', color: '#FF8011', fontSize: 16 }}
            className={`umami--click--explore`}
            onClick={() => {
              document.getElementById('explore-destinations').scrollIntoView({ behavior: 'smooth' })
            }}>
            explore our cities
          </span>
        </div>
      </div>

      {searchSuggestions.length > 0 && (
        <div className={styles.searchSuggestionContainer}>
          {searchSuggestions.map((e, i) => {
            return (
              <div
                key={`searchSuggestion_${i}`}
                className={`${styles.searchSuggestionRow} umami--click--city-suggestion`}
                onClick={() => {
                  launchExplorer(e)
                }}>
                {e.type === 'city' ? `${e.title}, ${e.country_title}` : e.title}
              </div>
            )
          })}
        </div>
      )}

      <div className={styles.explainerSubtitle2}>
        Airbnb rooms, hotels, cafes, mountain lodges or maybe a sunny villa in the south?
        <br />
        Find something that suits your lifestyle <i>and</i> your work requirements.
      </div>
    </div>
  )
}
