import { useEffect, useState } from "react";
import { Networking } from '../helpers/networking';

export default function MenuAvatarPoints(props) {

  const [userData, setUserData] = useState(false);

  useEffect(() => {
    const fetchAccountDetails = () => {
      Networking.fetchUserScore()
      .then((data) => { setUserData(data) })
    };
    fetchAccountDetails();
  }, [])

  if (!userData) return (null);

  return (
  <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
    <div style={{backgroundColor:"#666", color: "white", minWidth: 25, textAlign: "center", borderRadius: 20, padding:5, marginTop: 12, marginLeft: 10, fontWeight: "bold", fontSize: 11}}>
      {userData.score}
    </div>
  </div>
  )
}