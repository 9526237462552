import { useEffect, useState, useMemo } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import { Networking } from '../../helpers/networking'
import { Locations } from '../../helpers/locations'
import Map from '../../components/city_map'

import LocationRow from '../../components/location_row'
import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title'
import Loading from '../../components/loading'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGhost } from '@fortawesome/free-solid-svg-icons'

import styles from './index.module.css'
import FeatureToggle from '../../components/feature_toggle'

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export default function ExplorePage() {
  const params = useParams()
  const query = useQuery()

  const [locations, setLocations] = useState([])
  const [hiddenLocations, setHiddenLocations] = useState(0)
  const [cityDetails, setCityDetails] = useState(null)
  const [loading, setLoading] = useState(true)

  const [categoryFilter, setCategoryFilter] = useState([])

  const toggleFilter = (input, itemId) => {
    if (input.indexOf(itemId) >= 0) {
      return input.filter((i) => i !== itemId)
    } else {
      return input.concat([itemId])
    }
  }

  const applyCategoryFilter = (data) => {
    if (categoryFilter.length === 0) return data
    return data.filter((item) => categoryFilter.indexOf(item.location_type) >= 0)
  }

  useEffect(() => {
    let category = query.get('c')
    if (category) {
      setCategoryFilter([category])
    }
  }, [query])

  useEffect(() => {
    const fetchData = async () => {
      const locationResults = await Networking.fetchLocationsForCity(params.country, params.city)
      const cityDetails = await Networking.fetchCityDetails(params.country, params.city)
      setLocations(locationResults.results || [])
      setHiddenLocations(locationResults.hidden_count || 0)
      setCityDetails(cityDetails || null)
      setLoading(false)
    }

    fetchData()
  }, [params, categoryFilter])

  if (loading) {
    return <Loading page={true} />
  }

  if (locations.length === 0 && !loading) {
    return (
      <PageContainer>
        <FontAwesomeIcon icon={faGhost} color="white" size="8x" style={{ marginBottom: 10 }} />
        <div style={{ fontSize: 40, marginBottom: 40 }}>Oh...</div>
        Sorry, we are still waiting for more information in this part of the world.
        <br />
        <br />
        <Link to="/add">Know a place?</Link>
      </PageContainer>
    )
  }

  // Calculate the hidden entries
  var hiddenEntries = []
  for (var i = 0; i < hiddenLocations; i++) {
    hiddenEntries.push(<LocationRow key={`location_premium_${i}`} data={null} />)
  }

  // Prepare available locations
  var availableLocations = applyCategoryFilter(locations).map((e, i) => {
    return <LocationRow key={`location_${i}`} data={e} />
  })

  // Mix and match them
  var displayList = hiddenEntries.concat(availableLocations)
  //shuffleArray(displayList)

  return (
    <PageContainer>
      <PageTitle
        title={`${Locations.formatLocationTitle(params.city)}, ${Locations.formatLocationTitle(
          params.country
        )}`}
      />

      <PageTitle title={`I am specifically looking for...`} isSubtitle={true} />

      <div className={styles.categoryFilters}>
        <FeatureToggle
          title={'Cafes'}
          on={categoryFilter.indexOf('df70cdb7-5fb0-40df-9511-e30fd417cb3c') >= 0}
          onChanged={() => {
            setCategoryFilter(toggleFilter(categoryFilter, 'df70cdb7-5fb0-40df-9511-e30fd417cb3c'))
          }}
        />
        <FeatureToggle
          title={'Airbnbs'}
          on={categoryFilter.indexOf('957ae8c8-9eea-4575-b11b-7f583e956eb7') >= 0}
          onChanged={() => {
            setCategoryFilter(toggleFilter(categoryFilter, '957ae8c8-9eea-4575-b11b-7f583e956eb7'))
          }}
        />
        <FeatureToggle
          title={'Hotels'}
          on={categoryFilter.indexOf('99a3450b-b66f-4526-bfc1-213b7e6d697a') >= 0}
          onChanged={() => {
            setCategoryFilter(toggleFilter(categoryFilter, '99a3450b-b66f-4526-bfc1-213b7e6d697a'))
          }}
        />
        <FeatureToggle
          title={'Apartments'}
          on={categoryFilter.indexOf('a9aa7083-3588-4a6e-932d-b46a528c3b40') >= 0}
          onChanged={() => {
            setCategoryFilter(toggleFilter(categoryFilter, 'a9aa7083-3588-4a6e-932d-b46a528c3b40'))
          }}
        />
        <FeatureToggle
          title={'Co-Working Spaces'}
          on={categoryFilter.indexOf('5ef024db-347c-4a31-9d6e-3d7abcf8c2e2') >= 0}
          onChanged={() => {
            setCategoryFilter(toggleFilter(categoryFilter, '5ef024db-347c-4a31-9d6e-3d7abcf8c2e2'))
          }}
        />
      </div>

      {cityDetails && applyCategoryFilter(locations).length > 0 && (
        <Map
          center={[cityDetails.lat, cityDetails.lng]}
          locations={applyCategoryFilter(locations)}
        />
      )}

      <br />
      <br />

      {displayList.length === 0 ? (
        <div>
          We haven't collected any intelligence for this part of the world yet.{' '}
          <a href="/add">Can you help?</a>
        </div>
      ) : (
        <PageTitle title={`Here are some places our community recommends`} isSubtitle={true} />
      )}

      <div style={{ marginTop: 10, width: '100%' }}>
        {displayList.length > 0 &&
          displayList.map((e, i) => {
            return e
          })}
      </div>
    </PageContainer>
  )
}
