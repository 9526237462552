export default function InputWithLabel(props) {
    var minimumWidth = "auto";
    if (props.minWidth && props.minWidth === "s") minimumWidth = 50;
    if (props.minWidth && props.minWidth === "m") minimumWidth = 100;
    if (props.minWidth && props.minWidth === "mm") minimumWidth = 250;
    if (props.minWidth && props.minWidth === "xl") minimumWidth = 300;
    if (props.minWidth && props.minWidth === "half") minimumWidth = "50%";
    if (props.minWidth && props.minWidth === "full") minimumWidth = "100%";
    return (
        <div style={{marginBottom: 30}}>
            <div style={{marginBottom: 10, display: "flex"}}>
                <div>
                    {props.label||""}
                </div>
                <div style={{flexGrow:1}}></div>
                {
                    props.required && 
                    <div style={{color:"#777", fontSize: 12, marginLeft: 5, opacity: 0.6, marginTop: 3}}>Required</div>
                }
            </div>
            <input
                onKeyDown={props.onKeyDown || null}
                style={{minWidth:minimumWidth}}
                placeholder={props.placeholder || ""}                 
                type={props.type || "text"} 
                value={props.value} 
                onChange={(e)=>{props.onChange(e.target.value) }} 
            />        
        </div>
    )
}