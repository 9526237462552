import { useState } from 'react'
import styles from './score_panel.module.css'
export default function ScorePanel(props) {
  let blockColor =
    typeof props.polarity !== 'undefined'
      ? props.polarity > 0
        ? '0, 204, 4' // Positive
        : '255, 0, 0' // Negative
      : '255, 104, 4' // Default
  const [internalScore, setInternalScore] = useState(props.score || 0)
  // Expects a props.score of 0 to 100
  if (typeof props.score === 'undefined' || props.score < 0 || props.score > 100) return null
  return (
    <div className={styles.scoreContainer} style={{ ...(props.style ? props.style : {}) }}>
      <div
        onMouseOver={() => setInternalScore(10)}
        onMouseOut={() => setInternalScore(props.score || 0)}
        onClick={() => {
          if (props.onUpdate) props.onUpdate(10)
        }}
        style={{ backgroundColor: internalScore > 5 ? `rgba(${blockColor}, 0.2)` : '' }}></div>
      <div
        onMouseOver={() => setInternalScore(40)}
        onMouseOut={() => setInternalScore(props.score || 0)}
        onClick={() => {
          if (props.onUpdate) props.onUpdate(40)
        }}
        style={{ backgroundColor: internalScore > 20 ? `rgba(${blockColor}, 0.4)` : '' }}></div>
      <div
        onMouseOver={() => setInternalScore(60)}
        onMouseOut={() => setInternalScore(props.score || 0)}
        onClick={() => {
          if (props.onUpdate) props.onUpdate(60)
        }}
        style={{ backgroundColor: internalScore > 40 ? `rgba(${blockColor}, 0.5)` : '' }}></div>
      <div
        onMouseOver={() => setInternalScore(80)}
        onMouseOut={() => setInternalScore(props.score || 0)}
        onClick={() => {
          if (props.onUpdate) props.onUpdate(80)
        }}
        style={{ backgroundColor: internalScore > 60 ? `rgba(${blockColor}, 0.7)` : '' }}></div>
      <div
        onMouseOver={() => setInternalScore(100)}
        onMouseOut={() => setInternalScore(props.score || 0)}
        onClick={() => {
          if (props.onUpdate) props.onUpdate(100)
        }}
        style={{ backgroundColor: internalScore > 80 ? `rgba(${blockColor}, 1)` : '' }}></div>
    </div>
  )
}
