import styles from './city_row.module.css'

export default function CityRow({targetUrl, data}) {
  return (
    <a href={targetUrl}>
      <div className={styles.rowContainer}>
        {data.title}
      </div>
    </a>
  )
}