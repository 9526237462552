import { useParams } from 'react-router-dom';
import Loading from "../../components/loading";

export default function VerificationForwarder() {
  const { code } = useParams();
  if (typeof(code)!=="undefined") {
    document.location.href = `https://api.lonelydesk.com/account/verify/${code}`;
  }
  return (
    <div style={{width:"100%", height: "100%", marginTop:200, textAlign:"center"}}>
      One moment please, we're verifying this for you<br/><br/>
      <Loading/>
    </div>
  )
}
