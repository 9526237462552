import { Networking } from "../helpers/networking"
import { useEffect, useState } from "react"
import InputWithLabel from "./input_with_label"
import styles from './location_finder.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function LocationFinder(props) {

  const [q, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [hasPickedResult, setHasPickedResult] = useState(false);
  const [pickedCity, setPickedCity] = useState("");
  const [pickedCountry, setPickedCountry] = useState("");

  const search = (q) => {
    Networking.searchSuggestions(q, true)
    .then((data) => {
      setResults(data);
      console.log(data);
    })
  };    

  return (
    <div style={{marginBottom:0}}>
        <div style={{marginBottom:10}}>Where is this place?</div>
        
        {hasPickedResult && 
        <div className={styles.resultPickedPanel}>
          <div className={styles.resultPicked}>{pickedCity}, {pickedCountry}</div>
          <div className={styles.clearButton} onClick={()=>{ props.onChange(null, null); setHasPickedResult(false); setPickedCity(""); setPickedCountry("") }}>
            <FontAwesomeIcon icon={faTimesCircle} color={"orange"} style={{marginRight:5}}/> {props.title}
          </div>
        </div>
        }

        {!hasPickedResult &&

        <div>
          <InputWithLabel
            minWidth="full"
            placeholder="Paris, France"
            value={q}
            onChange={(data) => {
              setQuery(data);
              search(q);
            }}
          />
          { results.length > 0 && 
          <div className={styles.resultPanel}>
            {
              results.map((e,i) => {
                return (
                  <div 
                    onClick={()=>{ props.onChange(e.uuid, e.country_uuid); setResults([]); setHasPickedResult(true); setPickedCity(e.title); setPickedCountry(e.country_title); }}
                    className={styles.resultRow} 
                    key={`result_${i}`}>
                      {e.title}, {e.country_title}
                  </div>
                )
              })
            }
          </div>            
          }
        </div>
      }
    </div>
  )
}