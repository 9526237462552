import CityTile from '../components/city_tile'
import styles from './featured_cities.module.css';

export default function FeaturedCities(props) {
  return (
    <div className={styles.tileContainer}>
      {
        (props.cities || []).slice(0,16).map((e,i) => {
          return <CityTile key={`city_${i}`} data={e}/>
        })
      }
    </div>
  )
}
