import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as filledHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import styles from './page_title.module.css'
export default function PageTitle(props) {
  if (props.isSubtitle) {
    return (
      <h2
        className={`${styles.subHeader} ${props.withUnderline ? styles.subline : ''}`}
        style={{ textAlign: props.centered ? 'center' : 'left' }}>
        {props.title}
      </h2>
    )
  }
  return (
    <h1
      className={`${styles.header} ${props.withUnderline ? styles.subline : ''}`}
      style={{ textAlign: props.centered ? 'center' : 'left' }}>
      {props.title}
      {props.likeable && (
        <div className={styles.heart} onClick={props.onLikeClick}>
          <FontAwesomeIcon
            icon={props.liked ? filledHeart : faHeart}
            size="1x"
            color={props.liked ? '#dd0000' : '#ccc'}
          />
        </div>
      )}
    </h1>
  )
}
