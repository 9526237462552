import { useParams } from 'react-router-dom';

import PageContainer from '../../components/page_container';
import PageTitle from '../../components/page_title';
import CityRow from '../../components/city_row';

import { Networking } from '../../helpers/networking';
import { Locations } from '../../helpers/locations';
import { useEffect, useState } from 'react';

export default function CountryPage() {

  const [locations, setLocations] = useState([])
  const { country } = useParams()

  useEffect(() => {
    Networking.fetchCitiesForCountry(country)
    .then((data) => {
      setLocations(data.results)
    })
  }, [country])

  return (
    <PageContainer>
      <PageTitle title={`${Locations.formatLocationTitle(country)}`}/>

      <div style={{width:"100%"}}>
        <PageTitle title={`Do you already know where you're going in ${Locations.formatLocationTitle(country)}?`} isSubtitle={true}/>
        Feel free to browse through the available cities, our community is adding more places every day.
      </div>

      <div style={{marginTop:30, width: "100%"}}>
        {
          locations.map((e,i) => {
            return <CityRow key={`location_${i}`} data={e} targetUrl={`/explore/${country}/${e.title.toLowerCase()}`}/>
          })
        }
      </div>
    </PageContainer>
  )
}

export async function getServerSideProps(context) {
  const data = await Networking.fetchCitiesForCountry(context.query.country);
  return { props: { locations: data.results } }
}