import {
  faCoffee,
  faBed,
  faPlaneDeparture,
  faHouseLaptop,
  faDoorOpen,
  faWifiStrong,
  faUserCheck,
  faBookOpen,
  faUsers,
  faHandHoldingUsd,
  faPlug,
  faCouch,
  faDumbbell,
  faTv,
  faVolumeDown
} from '@fortawesome/free-solid-svg-icons'
import { faAirbnb } from '@fortawesome/free-brands-svg-icons'

export const Locations = {
  formatLocationTitle: (str) => {
    if (!str || str.length <= 0) return str
    if (str.toUpperCase() === 'UK') return 'United Kingdom'
    return str.toUpperCase()[0] + str.substring(1)
  },

  getDetails: (location_type) => {
    var locationTypeDetails = {
      title: 'Just a place',
      color: '#ffffff',
      icon: 'faCoffee'
    }
    var features = []
    switch (location_type) {
      case '99a3450b-b66f-4526-bfc1-213b7e6d697a':
        locationTypeDetails = { icon: faBed, color: '#ff70d2', title: 'Hotel' }
        break
      case '957ae8c8-9eea-4575-b11b-7f583e956eb7':
        locationTypeDetails = { icon: faAirbnb, color: '#FF385C', title: 'Airbnb' }
        break
      case '8da73284-38d0-41bd-8dc5-ec0eb9426652':
        locationTypeDetails = { icon: faPlaneDeparture, color: '#cfd9ff', title: 'Airport' }
        break
      case 'df70cdb7-5fb0-40df-9511-e30fd417cb3c':
        locationTypeDetails = { icon: faCoffee, color: '#ffbc70', title: 'Cafe' }
        break
      case '5ef024db-347c-4a31-9d6e-3d7abcf8c2e2':
        locationTypeDetails = { icon: faHouseLaptop, color: '#91fffd', title: 'Coworking Space' }
        break
      case 'a9aa7083-3588-4a6e-932d-b46a528c3b40':
        locationTypeDetails = { icon: faDoorOpen, color: '#fff7a3', title: 'Apartment' }
        break
      case 'a05daa28-1c11-4a29-b500-4a913a2dca9c':
        locationTypeDetails = { icon: faBookOpen, color: '#fff7a3', title: 'Library' }
        break
      case 'a2eaf132-1af1-4f94-9048-048317ed4bc5':
        locationTypeDetails = { icon: faUsers, color: '#fff7a3', title: 'Public Space' }
        break
      default:
        locationTypeDetails = { icon: faUsers, color: '#fff7a3', title: '' }
        break
    }
    return locationTypeDetails
  },

  // For future copy & pastes:
  /*
        '99a3450b-b66f-4526-bfc1-213b7e6d697a', // Hotel
        '957ae8c8-9eea-4575-b11b-7f583e956eb7', // Airbnb
        '8da73284-38d0-41bd-8dc5-ec0eb9426652', // Airport
        'df70cdb7-5fb0-40df-9511-e30fd417cb3c', // Cafe
        '5ef024db-347c-4a31-9d6e-3d7abcf8c2e2', // Coworking Space
        'a9aa7083-3588-4a6e-932d-b46a528c3b40', // Apartment
        'a05daa28-1c11-4a29-b500-4a913a2dca9c', // Library
        'a2eaf132-1af1-4f94-9048-048317ed4bc5' // Public Space
*/

  listOfFeatures: (locationType) => {
    console.log(locationType)
    var featureList = []
    if (
      [
        '99a3450b-b66f-4526-bfc1-213b7e6d697a', // Hotel
        '8da73284-38d0-41bd-8dc5-ec0eb9426652', // Airport
        'df70cdb7-5fb0-40df-9511-e30fd417cb3c', // Cafe
        '5ef024db-347c-4a31-9d6e-3d7abcf8c2e2', // Coworking Space
        'a05daa28-1c11-4a29-b500-4a913a2dca9c', // Library
        'a2eaf132-1af1-4f94-9048-048317ed4bc5' // Public Space
      ].indexOf(locationType) >= 0
    ) {
      featureList.push({
        feature_uuid: '46d2896a-e601-4633-88f4-9f2c5b526cbf',
        short_title: 'Costs',
        description: 'You may need to pay to use the Wi-Fi or for your stay',
        icon: faHandHoldingUsd,
        color: '#ff0000',
        polarity: -1
      })
    }

    if (
      [
        '99a3450b-b66f-4526-bfc1-213b7e6d697a', // Hotel
        '957ae8c8-9eea-4575-b11b-7f583e956eb7', // Airbnb
        '8da73284-38d0-41bd-8dc5-ec0eb9426652', // Airport
        'df70cdb7-5fb0-40df-9511-e30fd417cb3c', // Cafe
        '5ef024db-347c-4a31-9d6e-3d7abcf8c2e2', // Coworking Space
        'a9aa7083-3588-4a6e-932d-b46a528c3b40', // Apartment
        'a05daa28-1c11-4a29-b500-4a913a2dca9c', // Library
        'a2eaf132-1af1-4f94-9048-048317ed4bc5' // Public Space
      ].indexOf(locationType) >= 0
    ) {
      featureList.push({
        feature_uuid: 'ea6319cc-f973-4a11-9b54-29087640897d',
        short_title: 'Fast Wi-Fi',
        description: 'Really good Wi-Fi, plenty for video conferences',
        icon: faWifiStrong,
        color: '#00ff00',
        polarity: 1
      })
    }

    if (
      [
        '8da73284-38d0-41bd-8dc5-ec0eb9426652', // Airport
        'df70cdb7-5fb0-40df-9511-e30fd417cb3c', // Cafe
        'a05daa28-1c11-4a29-b500-4a913a2dca9c', // Library
        'a2eaf132-1af1-4f94-9048-048317ed4bc5' // Public Space
      ].indexOf(locationType) >= 0
    ) {
      featureList.push({
        feature_uuid: '7b73ba32-0541-4a87-96de-88859bcce30c',
        short_title: 'Power Sockets',
        description: 'You can charge your devices here',
        icon: faPlug,
        color: '#00ff00',
        polarity: 1
      })
    }

    if (
      [
        '99a3450b-b66f-4526-bfc1-213b7e6d697a', // Hotel
        '957ae8c8-9eea-4575-b11b-7f583e956eb7', // Airbnb
        'df70cdb7-5fb0-40df-9511-e30fd417cb3c', // Cafe
        '5ef024db-347c-4a31-9d6e-3d7abcf8c2e2', // Coworking Space
        'a9aa7083-3588-4a6e-932d-b46a528c3b40', // Apartment
        'a05daa28-1c11-4a29-b500-4a913a2dca9c', // Library
        'a2eaf132-1af1-4f94-9048-048317ed4bc5' // Public Space
      ].indexOf(locationType) >= 0
    ) {
      featureList.push({
        feature_uuid: 'a8c8bbd6-4128-471f-985a-1ff8bec32a62',
        short_title: 'Comfortable Seats',
        description: 'The chairs are comfortable for longer stays',
        icon: faCouch,
        color: '#00ff00',
        polarity: 1
      })
    }

    if (
      [
        '99a3450b-b66f-4526-bfc1-213b7e6d697a', // Hotel
        '957ae8c8-9eea-4575-b11b-7f583e956eb7', // Airbnb
        '8da73284-38d0-41bd-8dc5-ec0eb9426652', // Airport
        'df70cdb7-5fb0-40df-9511-e30fd417cb3c', // Cafe
        '5ef024db-347c-4a31-9d6e-3d7abcf8c2e2', // Coworking Space
        'a9aa7083-3588-4a6e-932d-b46a528c3b40', // Apartment
        'a05daa28-1c11-4a29-b500-4a913a2dca9c', // Library
        'a2eaf132-1af1-4f94-9048-048317ed4bc5' // Public Space
      ].indexOf(locationType) >= 0
    ) {
      featureList.push({
        feature_uuid: 'b35d8579-2672-4b1e-8ac6-51f8dc30f471',
        short_title: 'Quiet',
        description: 'Not very noisy, good for calls',
        icon: faVolumeDown,
        color: '#00ff00',
        polarity: 1
      })
    }

    if (
      [
        '99a3450b-b66f-4526-bfc1-213b7e6d697a', // Hotel
        '957ae8c8-9eea-4575-b11b-7f583e956eb7', // Airbnb
        'a9aa7083-3588-4a6e-932d-b46a528c3b40' // Apartment
      ].indexOf(locationType) >= 0
    ) {
      featureList.push({
        feature_uuid: '2afd2922-00d3-4112-889d-b95cee931aa0',
        short_title: 'External Monitor',
        description: 'TV or Display with HDMI, AirPlay or Miracast',
        icon: faTv,
        color: '#00ff00',
        polarity: 1
      })
    }

    if (
      [
        '99a3450b-b66f-4526-bfc1-213b7e6d697a', // Hotel
        '957ae8c8-9eea-4575-b11b-7f583e956eb7', // Airbnb
        'a9aa7083-3588-4a6e-932d-b46a528c3b40' // Apartment
      ].indexOf(locationType) >= 0
    ) {
      featureList.push({
        feature_uuid: 'efe12bec-a981-4c93-bbd9-57fb5911166b',
        short_title: 'Gym',
        description: 'Has gym in-house or nearby',
        icon: faDumbbell,
        color: '#00ff00',
        polarity: 1
      })
    }

    /*
    featureList.push({
      feature_uuid: "",
      short_title: "Tested",
      description: "Members of our community have collected internet speed tests from this location.",
      icon: faUserCheck,
      color: "#fc0876",
    });
    */
    return featureList
  },

  getFeatures: (locationType, featureData) => {
    // Note: Each location type may have a different set of features. So figure out which features
    // we want to return for our particular location type first.

    var featureList = []
    Locations.listOfFeatures(locationType).forEach((potentialFeature) => {
      // Check if our location feature data contains records for a particular feature
      let foundFeatureData = featureData.find(
        (fd) => fd.feature_uuid === potentialFeature.feature_uuid
      )
      if (foundFeatureData) {
        featureList.push({ ...potentialFeature, weight: foundFeatureData.w })
      } else {
        featureList.push({ ...potentialFeature, weight: 0 })
      }
    })
    return featureList
  }
}
