import { useState, useEffect, useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Networking } from '../../helpers/networking';
import { User } from '../../helpers/user';

import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title';
import Loading from '../../components/loading';
import TabMenu from '../../components/tab_menu';

import styles from './index.module.css';
import Button from '../../components/button';

export default function AccountPage() {

  const navigate = useNavigate();
  const [accountDetails, setAccountDetails] = useState(null);
  const { tab } = useParams();

  const fetchAccountDetails = useCallback(async () => {
    try {
      const details = await Networking.fetchUserScore();
      if (details) {
        setAccountDetails(details);
      } else {
        setAccountDetails(null);
        navigate("/login");
      }  
    } catch (e) {
      // We probably don't have a valid user, so let's return to the start.
      console.log(e);
      navigate("/login");
    }
  }, [setAccountDetails, navigate])

  useEffect(() => {
    if (accountDetails === null) { fetchAccountDetails() }
  }, [accountDetails, fetchAccountDetails])

  if (accountDetails === null) return (
    <PageContainer>
      <div style={{width:"100%", textAlign: "center"}}>
        <Loading/>
      </div>
    </PageContainer>
  );

  return (
    <PageContainer>
      <div style={{width:"100%"}}>

        <div style={{textAlign: "right", marginTop:-20, marginBottom:-20}}>
          <Link to="/" onClick={()=>{User.logout()}}>Log out</Link>
        </div>

        <PageTitle title="My Account"/>

        <TabMenu          
          tabs={[
            { title: "Overview", link: "/account/overview", isActive: (tab==="overview" || tab==="") },
            { title: "Plan", link: "/account/plan", isActive: (tab==="plan") }
          ]}
        />

        { tab === "overview" && 
        <div style={{maxWidth: 450, backgroundColor: "#010101", padding: 30}}>

          <h3>Everyone contributes, everyone benefits. </h3>

          Your roaming score determines the level of collective intelligence you're eligible to access.
          <br/><br/>Contribute, help others, be an active member of the community and your score will go up.

          <div className={styles.scorePanel}>
            <div className={styles.scorePanelNumber}>
              {accountDetails.score}
            </div>
            <div className={styles.scorePanelTitle}>
              ROAMING SCORE
            </div>
          </div>
                  
          <div style={{backgroundColor:"transparent", textAlign: "center", padding: 0, borderRadius: 15, fontSize: 14, marginTop: 30, lineHeight: "1.5em", color: "#777"}}>
            No time to contribute? You can support us with a subscription and
            pick a level that suits your lifestyle for full access here:
            <div style={{padding:10, textAlign: "center", marginTop: 10}}>
              <Button title={"Upgrade"} onClick={()=>{ navigate("/account/plan") }}/>
            </div>
          </div>

        </div>

        }

        { tab === "plan" && 
        <div>
          This will become available very soon.<br/><br/>If you want to be an early tester, please reach out to: <a href="mailto:office@prototypes.at?subject=Early%20Tester">office@prototypes.at</a> and we'll get you in ahead of the crowd.
        </div>
        }

      </div>
    </PageContainer>
  )
}