import { useNavigate } from 'react-router-dom'
import Button from '../../components/button'
import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title'

export default function JoinUs() {
  const navigate = useNavigate()

  return (
    <PageContainer>
      <div
        style={{
          maxWidth: 400,
          width: '100%',
          marginTop: 10,
          textAlign: 'center'
        }}>
        <PageTitle title="Members Only" centered />
        <strong>A free account is all you need.</strong>
        <br />
        <br />
        <span style={{ color: 'orange' }}>It only takes 11 seconds to create an account.</span>
        <br />
        <br />
        You can like and collect locations, keep track of places you've enjoyed, and earn community
        respect for helping others learn more about great spaces to work.
        <br />
        <br />
        <br />
        <Button title={'Join Lonely Desk'} onClick={() => navigate('/register')} />
      </div>
    </PageContainer>
  )
}
