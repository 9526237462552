import { useEffect, useState } from 'react'
import { Networking } from '../helpers/networking'
import Button from './button'
import styles from './comment_panel.module.css'
import PanelWithButton from './panel_with_button'
export default function CommentPanel(props) {
  const [comment, setComment] = useState('')
  const [panelVisible, showPanel] = useState(false)
  const [thanksVisible, showThanks] = useState(false)

  const postComment = () => {
    Networking.addLocationComment({
      location_uuid: props.location_uuid,
      comment: comment
    })
      .then((r) => {
        showPanel(false)
        showThanks(true)
        if (props.onPosted) props.onPosted(r.comment_uuid)
      })
      .catch((e) => {
        showPanel(false)
        showThanks(true)
      })
  }

  useEffect(() => {
    if (comment.length > 250) setComment(comment.substring(0, 250))
  }, [comment])

  if (thanksVisible) {
    return (
      <div className={styles.panel} style={{ fontSize: 16 }}>
        <strong>Thanks for contributing!</strong>
        <br />
        <br />
        This will help others understand the location a lot better. Please note: it can take a day
        or so for your comment to be approved, please be patient.
      </div>
    )
  }

  if (!panelVisible) {
    return (
      <div style={{ width: '100%' }}>
        <PanelWithButton
          buttonRight={false}
          title={'Add Comment'}
          label={'Do you have anything to add here?'}
          onClick={() => {
            showPanel(true)
          }}
        />
      </div>
    )
  }

  return (
    <div className={styles.panel}>
      <textarea
        placeholder="Anything useful you want to share about this location? Keep it nice, short and to the point. Respect the owners, make sure it helps your peers."
        className={styles.text}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value)
        }}
        onKeyDown={(e) => {
          setComment(e.target.value)
        }}></textarea>
      <div style={{ display: 'flex', alignItems: 'center', height: 30, marginTop: 10 }}>
        <Button title={'Post'} onClick={postComment} /> &nbsp; &nbsp;
        <Button title={'Cancel'} secondary={true} onClick={() => showPanel(false)} />
        <div style={{ fontSize: 12, marginLeft: 10 }}>{250 - comment.length} characters left</div>
      </div>
    </div>
  )
}
