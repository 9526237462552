import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Networking } from '../../helpers/networking'
import { User } from '../../helpers/user'
import { Locations } from '../../helpers/locations'

import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title'
import FeatureToggle from '../../components/feature_toggle'
import Loading from '../../components/loading'
import PanelWithButton from '../../components/panel_with_button'
import Button from '../../components/button'
import Map from '../../components/city_map'

import styles from './index.module.css'

export default function EditLocationPage() {
  const navigate = useNavigate()

  const pid = useParams().place_id

  const [data, setData] = useState(null)
  const [features, setFeatures] = useState(null)
  const [locationGuess, setLocationGuess] = useState(null)
  const [alternativeLocations, setAlternativeLocations] = useState([])

  const [locationIdentifiedSuccessfully, setLocationIdentifiedSuccessfully] = useState(null)

  useEffect(() => {
    // This page requires a logged in user, so redirect if not authenticated
    if (!User.isLoggedIn()) {
      navigate('/?pleaselogin')
      return
    }

    if (pid !== null) {
      if (data === null) {
        Networking.fetchLocationDetails(pid)
          .then((locationDetails) => {
            setData(locationDetails)
          })
          .catch((e) => {})
      }
      if (features === null) {
        reloadFeatures()
      }
      if (locationGuess === null) {
        Networking.attemptLocationIdentification(pid)
          .then((data) => {
            setLocationGuess(data.result || null)
            setAlternativeLocations(data.alternatives || [])
          })
          .catch((e) => {
            setLocationGuess(false)
          })
      }
    }
  })

  const reloadFeatures = () => {
    Networking.fetchLocationFeatures(pid)
      .then((data) => {
        setFeatures(data)
      })
      .catch((e) => {
        setFeatures([])
      })
  }

  const setFeature = (feature_uuid, status) => {
    if (status) {
      Networking.addFeature({
        feature_uuid: feature_uuid,
        location_uuid: pid
      }).then((result) => {
        reloadFeatures()
      })
    } else {
      Networking.removeFeature({
        feature_uuid: feature_uuid,
        location_uuid: pid
      }).then((result) => {
        reloadFeatures()
      })
    }
  }

  const markLocationCorrect = (alternative = false) => {
    Networking.updateLocationCoordinates(
      pid,
      alternative ? alternative.location.lat : locationGuess.location.lat,
      alternative ? alternative.location.lng : locationGuess.location.lng
    )
      .then((d) => {
        setLocationIdentifiedSuccessfully(true)
        if (alternative) setLocationGuess(alternative)
      })
      .catch((e) => {})
  }

  const markLocationIncorrect = () => {
    setLocationIdentifiedSuccessfully(false)
  }

  const isFeatureEnabled = (feature_uuid) => {
    return features.some((f) => f.feature_uuid === feature_uuid)
  }

  if (data === null || features === null || typeof features === 'undefined') {
    return (
      <PageContainer>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>
      </PageContainer>
    )
  }

  let locationCoordinates =
    locationGuess && locationGuess.location
      ? [
          {
            location_latitude: locationGuess.location.lat,
            location_longitude: locationGuess.location.lng
          }
        ]
      : []
  let locationCenterForMap =
    locationGuess && locationGuess.location
      ? [locationGuess.location.lat, locationGuess.location.lng]
      : []

  return (
    <PageContainer>
      <div style={{ width: '100%' }}>
        <PageTitle title={`${data.location_title}`} />

        <div style={{ marginBottom: 40 }}>
          Tell us a bit more about this location. Anything you know is helpful and make someone
          else's day.
        </div>

        <PageTitle isSubtitle={true} title="Features" />

        {Locations.listOfFeatures(data.location_type).map((feature, i) => {
          return (
            <FeatureToggle
              key={`feature_${i}`}
              title={feature.short_title}
              on={isFeatureEnabled(feature.feature_uuid)}
              onChanged={(t) => {
                if (t) {
                  Networking.addFeature({
                    location_uuid: pid,
                    feature_uuid: feature.feature_uuid
                  }).then(() => {
                    reloadFeatures()
                  })
                } else {
                  Networking.removeFeature({
                    location_uuid: pid,
                    feature_uuid: feature.feature_uuid
                  }).then(() => {
                    reloadFeatures()
                  })
                }
              }}
            />
          )
        })}

        <PageTitle isSubtitle={true} title="Location" />
        {locationGuess !== null && locationGuess !== false && (
          <div>
            {locationIdentifiedSuccessfully === null && (
              <div>
                We tried our best to identify the location, is this the correct location?
                <br />
                <div className={styles.identifiedLocation}>
                  {locationGuess.name}, {locationGuess.location.address},{' '}
                  {locationGuess.location.city} &nbsp; &nbsp;
                  <Button
                    title={'Yes'}
                    onClick={() => {
                      markLocationCorrect()
                    }}
                  />{' '}
                  <Button
                    title={'No'}
                    onClick={() => {
                      markLocationIncorrect()
                    }}
                  />
                </div>
              </div>
            )}

            {locationIdentifiedSuccessfully === false && alternativeLocations.length > 0 && (
              <div>
                <h3>Hmm, let's see what else we have... is it one of these perhaps?</h3>
                {alternativeLocations.slice(0, 5).map((al, i) => {
                  return (
                    <div
                      className={styles.identifiedLocation}
                      style={{ marginRight: 20 }}
                      key={`location_alternative_${i}`}>
                      {al.name}, {al.location.address}, {al.location.city} &nbsp; &nbsp;
                      <Button
                        title={'Yes'}
                        onClick={() => {
                          setLocationGuess(al)
                          markLocationCorrect(al)
                        }}
                      />{' '}
                      <Button
                        title={'No'}
                        onClick={() => {
                          setAlternativeLocations([])
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            )}

            {locationIdentifiedSuccessfully === false && alternativeLocations.length === 0 && (
              <div style={{ marginBottom: 40 }}>
                Okay, we'll have one of our community admins try and identify this location
                manually. Thanks!
              </div>
            )}
            {locationIdentifiedSuccessfully !== false && (
              <div>
                <Map
                  zoom={17}
                  center={locationCenterForMap}
                  locations={locationCoordinates}
                  noPopup={true}
                />
              </div>
            )}
          </div>
        )}

        <div className={styles.separator}></div>
        <PanelWithButton
          buttonRight={true}
          title={'Continue'}
          label={
            'Thanks a lot for all that information! This will be included in your status as soon as the system updates its aggregate.'
          }
          onClick={() => {
            navigate(`/place/${pid}`)
          }}
        />
      </div>
    </PageContainer>
  )
}
