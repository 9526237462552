import { Auth } from 'aws-amplify'

export const User = {

  logout: async () => {
    try {
      await Auth.signOut();
      document.location.href = '/';
    } catch (e) { console.log(e) }
  },

  getLoginUrl: (withState=false) => {
    return `/login`;
  },

  getLogoutUrl: () => {
    return `${process.env.NEXT_PUBLIC_API}/account/logout`;
  },

  getSignUpUrl: () => {
    return `/register`;
  },

  getCookie: (cname) => {
    if (typeof(document)==="undefined") return "";
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  parseJwt: (token) => {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  },

  isLoggedIn: async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) return user;
    } catch (e) {
      return false;
    }
    return false;
  },

  getEmail: async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) return user.attributes.email;
    } catch (e) {
      return false;
    }
  }


}