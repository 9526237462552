import { Networking } from "../helpers/networking";
import Loading from "../components/loading";
import { useEffect } from "react";

export default function LocationTypePicker(props) {

    if (!props.types) return <Loading/>

    const getListOfTypes = (data) => {
      return data.sort((a, b) => a.title.localeCompare(b.title));
    };

    return (
        <div style={{marginBottom: 15}}>
          <div style={{marginBottom:10}}>Which of the following describes this location best?</div>
          <select style={{width:"100%"}} value={props.locationType||""} onChange={(e) => { props.onChange(e.target.value) }}>
            <option value="">...</option>
            {
              getListOfTypes(props.types).map((locationType, i) => {
                  return (
                      <option key={`lktype_${i}`} value={locationType.uuid}>{locationType.title}</option>
                  )
              })
            }
          </select>
        </div>
    )
}