import { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from './button'

import styles from './feature_panel_button.module.css'
import { Networking } from '../helpers/networking'
import { User } from '../helpers/user'
import ScorePanel from './score_panel'

export default function FeaturePanelButton({ feature, location_uuid, onFeatureUpdate }) {
  let featureActive = feature.weight > 0
  const [hasFeedbacked, setFeedbackDone] = useState(false)
  const [userIsAuthenticated, setUserAuthenticated] = useState(false)

  const feedback = async (userScore) => {
    if (!userIsAuthenticated) {
      alert('Sorry, you need to be logged in for this')
    }
    await Networking.featureFeedback(location_uuid, feature.feature_uuid, Math.round(userScore))
    setFeedbackDone(true)
    if (onFeatureUpdate) {
      onFeatureUpdate()
    }
  }

  useEffect(() => {
    User.isLoggedIn().then((user) => {
      setUserAuthenticated(user)
    })
  }, [])

  console.log(feature)

  return (
    <div
      className={`${styles.panel} ${userIsAuthenticated ? '' : styles.panelGuestOverride} ${
        featureActive ? styles.featureExists : styles.featureNotDetected
      }`}>
      <div>
        <FontAwesomeIcon size={`2x`} icon={feature.icon} style={{ marginRight: 5 }} />
      </div>
      <div className={styles.title}>{feature.short_title}</div>
      <div className={styles.subtitle}>{feature.description}</div>

      {!hasFeedbacked && (
        <ScorePanel
          score={feature.weight}
          polarity={feature.polarity}
          style={{ marginTop: 10 }}
          onUpdate={feedback}
        />
      )}

      {hasFeedbacked && (
        <div className={styles.feedbackContainer}>
          <div
            style={{
              fontSize: 10,
              textAlign: 'center',
              width: '100%',
              marginTop: 15,
              color: '#00ff00'
            }}>
            Intelligence updated. Thanks!
          </div>
        </div>
      )}
    </div>
  )
}
