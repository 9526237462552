import { Networking } from "./helpers/networking";

const aws_configuration = {
  Auth: {
    identityPoolId: 'us-east-1:e3d2eb3e-0f69-4321-b44d-c5544e70baa0',  
    region: 'us-east-1', 
    userPoolId: 'us-east-1_02ddci5bg', 
    userPoolWebClientId: '19r6ri5r3n07afvvfm8hiji4nk',
    authenticationFlowType: 'USER_SRP_AUTH'
  },
  API: {
    endpoints: [
      { name: "locations", endpoint: `${Networking.apiServer()}/locations/` },
      { name: "account", endpoint: `${Networking.apiServer()}/account/` },
      { name: "speedtests", endpoint: `${Networking.apiServer()}/speedtests/` },
      { name: "comments", endpoint: `${Networking.apiServer()}/comments/` },
    ]
  }  
}
export default aws_configuration;