import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { User } from '../helpers/user'
import Hamburger from './menu_bar_hamburger'
import styles from './menu_bar.module.css'
import MenuAvatar from './menu_avatar'
import MenuAvatarPoints from './menu_avatar_points'

import Logo from '../assets/lonelydesk_logo.png'

export default function MenuBar(props) {
  const [userData, setUserData] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const loadUserDetails = async () => {
    const user = await User.isLoggedIn()
    if (user) {
      setUserData({ email: await User.getEmail() })
    } else {
      setUserData(null)
    }
  }

  useEffect(() => {
    loadUserDetails()
  }, [])

  return (
    <header className={styles.navContainer}>
      <nav className={styles.navBar}>
        <div className={styles.linkContainer}>
          <div className={styles.logo}>
            <img
              alt="logo"
              width={160}
              height={22.82}
              src={Logo}
              onClick={() => {
                document.location.href = '/'
              }}
            />
          </div>

          <div className={styles.mobileMenu}>
            <Hamburger
              isActive={mobileMenuOpen}
              onToggle={() => {
                setMobileMenuOpen(!mobileMenuOpen)
              }}
            />
            {mobileMenuOpen && (
              <div className={styles.mobileMenuPanel}>
                {userData && userData.email && (
                  <>
                    <div className={styles.mobileMenuItem}>
                      <Link to="/liked">Liked</Link>
                    </div>
                    <div className={styles.mobileMenuItem}>
                      <Link to="/add">Know a Place?</Link>
                    </div>
                    <div className={styles.mobileMenuItem}>
                      <a href={'/'} onClick={() => User.logout()}>
                        Sign out
                      </a>
                    </div>
                  </>
                )}
                {(!userData || !userData.email) && (
                  <>
                    <div className={styles.mobileMenuItem}>
                      <a href={User.getLoginUrl()}>Sign in</a>
                    </div>
                    <div className={styles.mobileMenuItem}>
                      <a href={User.getSignUpUrl()}>Join Lonely Desk</a>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className={styles.desktopMenu}>
            <div className={styles.spacer}></div>
            {!userData && (
              <>
                <Link to="/login">My Account</Link>
                <Link to="/register" style={{ backgroundColor: '#fafafa', color: '#333' }}>
                  <strong>Join</strong> LonelyDesk
                </Link>
              </>
            )}
            {userData && userData.email && (
              <>
                <Link to="/liked">My Favorites</Link>
                <Link to="/add">Know a Place?</Link>
                <Link to="/account/overview">
                  <MenuAvatar user={userData} />
                </Link>
                <MenuAvatarPoints user={userData} />
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  )
}
