import { MapContainer, Marker, Popup, TileLayer, L } from 'react-leaflet'
import { icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import styles from './city_map.module.css'

const MarkerIcon = icon({
  iconUrl: '/marker.png',
  iconSize: [50, 50]
})

const Map = (props) => {
  const centerOfMap = props.center
    ? props.center
    : [props.locations[0]['location_latitude'], props.locations[0]['location_longitude']]
  if (props.locations.length === 0 && !props.center) {
    return null
  }

  var bounds = props.locations.map((l) => [l.location_latitude, l.location_longitude])
  if (bounds.length === 0) bounds = [centerOfMap]

  return (
    <MapContainer
      //      center={centerOfMap}
      bounds={bounds}
      zoom={props.zoom || 15}
      scrollWheelZoom={false}
      className={styles.mapContainer}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.locations.map((e, i) => {
        if (e.location_latitude === null || typeof e.location_latitude === 'undefined') return null
        return (
          <Marker
            key={`marker_${i}`}
            icon={MarkerIcon}
            position={[e.location_latitude, e.location_longitude]}>
            {!props.noPopup && (
              <Popup>
                <a href={`/place/${e.location_uuid}`}>
                  {e.location_title}
                  <br />
                </a>
              </Popup>
            )}
          </Marker>
        )
      })}
    </MapContainer>
  )
}

export default Map
