import styles from './panel_with_button.module.css'
import Button from './button'

export default function PanelWithButton(props) {
  if (props.buttonRight) {
    return (
      <div className={styles.buttonRight}>
        <div className={styles.buttonRightLabel}>{props.label || ''}</div>
        <Button title={props.title} onClick={props.onClick} />
      </div>
    )
  } else {
    return (
      <div className={styles.buttonLeft}>
        <Button title={props.title} onClick={props.onClick} />
        <div className={styles.buttonLeftLabel}>{props.label || ''}</div>
      </div>
    )
  }
}
