import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTimeAgo from 'react-time-ago'

import { Networking } from '../../helpers/networking'
import { Locations } from '../../helpers/locations'
import { User } from '../../helpers/user'

import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title'
import Loading from '../../components/loading'
import FeaturePanelButton from '../../components/feature_panel_button'
import Button from '../../components/button'
import Speedtest from '../../components/speedtest'
import PanelWithButton from '../../components/panel_with_button'
import CommentPanel from '../../components/comment_panel'

import styles from './index.module.css'
import Map from '../../components/city_map'

export default function PlacePage() {
  const navigate = useNavigate()
  const params = useParams()

  const [data, setData] = useState(null)
  const [featureData, setFeatureData] = useState([])
  const [commentData, setCommentData] = useState([])
  const [speedtestsAvailable, setSpeedtestsAvailable] = useState(0)

  const [missingLocation, setMissingLocation] = useState(true)
  const [speedtestsAvgUp, setSpeedtestsAvgUp] = useState(null)
  const [speedtestsAvgDown, setSpeedtestsAvgDown] = useState(null)
  const [showSpeedtest, setShowSpeedtest] = useState(false)

  const [stillLoading, setStillLoading] = useState(true)
  const [userIsAuthenticated, setUserAuthenticated] = useState(false)

  const [locationLiked, setLocationLiked] = useState(false)

  const loadLocationFeatures = useCallback(async () => {
    const locationFeatures = await Networking.fetchLocationFeatures(params.place_id)
    if (locationFeatures === [] || locationFeatures.error) {
      setFeatureData([])
      if (locationFeatures.error && locationFeatures.error === 'login_required') {
        navigate(User.getLoginUrl(`place_${params.place_id}`))
      }
    } else {
      setFeatureData(locationFeatures)
    }
  }, [navigate, params.place_id])

  const updateIsLocationLiked = useCallback(async () => {
    const isLiked = await Networking.doesUserLikeLocation(params.place_id)
    setLocationLiked(isLiked)
  }, [params.place_id])

  const loadLocationComments = useCallback(async () => {
    const locationComments = await Networking.fetchLocationComments(params.place_id)
    setCommentData(locationComments)
  }, [params])

  useEffect(() => {
    const fetchData = async () => {
      // Start fetching the data
      const locationDetails = await Networking.fetchLocationDetails(params.place_id)
      const speedtestData = await Networking.speedtestsAvailable(params.place_id)

      await loadLocationFeatures()
      await loadLocationComments()
      await updateIsLocationLiked()

      // Now let's see what we've received
      if (locationDetails) {
        setData(locationDetails)
        if (!locationDetails.location_latitude || locationDetails.location_latitude === '') {
          setMissingLocation(true)
        } else {
          setMissingLocation(false)
        }
      }
      if (speedtestData && speedtestData.count > 0) {
        setSpeedtestsAvailable(speedtestData.count)
        setSpeedtestsAvgUp(speedtestData.avg_up)
        setSpeedtestsAvgDown(speedtestData.avg_down)
      }

      // All done?
      setStillLoading(false)
    }

    // Let's get some data
    fetchData()
  }, [params, navigate, loadLocationComments, loadLocationFeatures, updateIsLocationLiked])

  const postSpeedMeasurement = (data) => {
    Networking.postSpeedMeasurement(params.place_id, data)
      .then((d) => {
        setShowSpeedtest(false)
        document.location.reload()
      })
      .catch((e) => {
        setShowSpeedtest(false)
      })
  }

  const formatSpeedNumber = (n) => {
    if (n > 10) {
      return Math.round(n)
    } else {
      return Math.round(n * 10) / 10
    }
  }

  const toggleLike = async () => {
    await Networking.toggleLocationLike(params.place_id)
    updateIsLocationLiked()
  }

  useEffect(() => {
    User.isLoggedIn().then((user) => {
      setUserAuthenticated(user)
    })
  }, [])

  if (stillLoading || !data) {
    return (
      <PageContainer>
        <Loading page />
      </PageContainer>
    )
  }

  let locationTypeDetails = Locations.getDetails(data.location_type)
  let locationFeatures = Locations.getFeatures(data.location_type, featureData)

  return (
    <PageContainer>
      <div className={styles.locationHeader}>
        <PageTitle
          title={data.location_title}
          likeable={true}
          liked={locationLiked}
          onLikeClick={userIsAuthenticated ? toggleLike : () => navigate('/join')}
        />

        {data.location_link && data.location_link.length > 3 && (
          <div style={{ marginTop: 25, marginLeft: 20, marginRight: 20 }}>
            <Button
              title={`Website`}
              secondary={true}
              onClick={() => {
                window.open(data.location_link)
              }}
            />
          </div>
        )}

        <div style={{ flexGrow: 2 }}></div>

        <div className={styles.locationTypeIcon}>
          <FontAwesomeIcon
            icon={locationTypeDetails.icon}
            size="2x"
            color={locationTypeDetails.color}
          />
          <br />
          <div style={{ fontSize: 14, marginTop: 10, textAlign: 'center' }}>
            {Locations.getDetails(data.location_type).title}
          </div>
        </div>
      </div>

      <div className={styles.locationCity}>
        {data.country_title} &gt;{' '}
        <a href={`/explore/${data.country_title.toLowerCase()}/${data.city_title.toLowerCase()}`}>
          {data.city_title}
        </a>
      </div>

      {!missingLocation && (
        <>
          <Map locations={[data]} noPopup={true} />
          <br />
          <br />
        </>
      )}

      <PageTitle isSubtitle={true} title={`Location Features`} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%'
        }}>
        {locationFeatures.map((feature, featureIndex) => {
          return (
            <FeaturePanelButton
              key={`feature_${featureIndex}`}
              feature={feature}
              location_uuid={
                params.place_id
              } /* This is for the feedback button, so it knows where it belongs */
              onFeatureUpdate={() => {
                loadLocationFeatures()
              }}
            />
          )
        })}
      </div>

      <br />

      <PageTitle isSubtitle={true} title={`Speed Reports`} />
      <div style={{ width: '100%' }}>
        {speedtestsAvailable === 0 && (
          <div>There are not enough speed reports available yet for this location.</div>
        )}
        {speedtestsAvailable > 0 && (
          <div>
            <div className={styles.speedtestContainer}>
              <div className={styles.speedtestReading}>
                <div className={styles.speedtestReadingTitle}>DOWNLOAD</div>
                <div className={styles.speedtestReadingNumber}>
                  {formatSpeedNumber(speedtestsAvgDown)}
                </div>
                <div className={styles.speedtestReadingUnit}>Mbps</div>
              </div>
              <div className={styles.speedtestReading}>
                <div className={styles.speedtestReadingTitle}>UPLOAD</div>
                <div className={styles.speedtestReadingNumber}>
                  {formatSpeedNumber(speedtestsAvgUp)}
                </div>
                <div className={styles.speedtestReadingUnit}>Mbps</div>
              </div>
            </div>
          </div>
        )}
        <br />

        {userIsAuthenticated && !showSpeedtest && (
          <PanelWithButton
            buttonRight={true}
            title={'Run Speed Test'}
            label={
              'Are you at this location? You can always help out the community by running a quick speed test!'
            }
            onClick={() => {
              setShowSpeedtest(true)
            }}
          />
        )}

        {showSpeedtest && (
          <Speedtest
            onMeasurementComplete={(d) => {
              postSpeedMeasurement(d)
            }}
          />
        )}
      </div>

      <br />

      <PageTitle isSubtitle={true} title={`Community Intelligence`} />

      {!userIsAuthenticated && (
        <div style={{ textAlign: 'left', width: '100%' }}>
          You need to be logged in to see our community comments.
        </div>
      )}

      {userIsAuthenticated && (
        <CommentPanel
          location_uuid={params.place_id}
          onPosted={() => {
            loadLocationComments()
          }}
        />
      )}

      {userIsAuthenticated && (
        <div style={{ width: '100%', borderTop: '1px dashed #444', paddingTop: 15, marginTop: 15 }}>
          {commentData !== null && commentData.length === 0 && (
            <>
              We do not have any intelligence reports from our community yet. Have you been to this
              place? Let us know about it and raise your level.
            </>
          )}
          {commentData !== null && commentData.length > 0 && (
            <>
              {commentData.map((comment, ci) => {
                return (
                  <div style={{ paddingTop: 10, paddingBottom: 10 }} key={`comment_${ci}`}>
                    <div
                      style={{
                        padding: 15,
                        borderRadius: 10,
                        backgroundColor: '#161b30',
                        display: 'inline-block'
                      }}>
                      {comment.comment}
                    </div>
                    <div style={{ padding: 3, marginLeft: 0, fontSize: 12, color: '#666' }}>
                      Posted{' '}
                      <ReactTimeAgo date={parseInt(comment.created_at) * 1000} locale="en-GB" /> by{' '}
                      <strong>{comment.author_display_name || 'a member of our community'}</strong>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      )}
    </PageContainer>
  )
}
