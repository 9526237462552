import styles from './button.module.css';

export default function Button(props) {
  var buttonStyle = styles.primaryButton;
  if (props.secondary) { buttonStyle = styles.secondaryButton }
  if (props.disabled) { buttonStyle = `${styles.disabledButton} ${buttonStyle} ` }
  if (props.size && props.size === "large") { buttonStyle = `${styles.largeButton} ${buttonStyle} ` }
  if (props.size && props.size === "small") { buttonStyle = `${styles.smallButton} ${buttonStyle} ` }
  var extraStyle = { cursor: "pointer" };
  if (props.backgroundColor) { extraStyle["backgroundColor"] = props.backgroundColor; }
  return (
    <button 
      disabled={props.disabled||false} 
      className={buttonStyle} 
      onClick={props.onClick}
      style={extraStyle}
    >{props.title}</button>
  )
}