import styles from './city_tile.module.css'
import CityPlaceholder from '../assets/city_placeholder.webp'

export default function CityTile(props) {
  return (
    <a href={`/explore/${props.data.city_slug.replace("_","/")}`} className={`umami--click--city-title`}>
      <div className={styles.tile}>        
        <div className={styles.tileImage} style={{backgroundImage:`url('/locations/${props.data.city_image.replace(".jpg", ".webp")}'), url(${CityPlaceholder})`}}></div>
        <div className={styles.tileTitle}>
          <div style={{fontSize:18, color:"white"}}>{props.data.city_title}</div>
          <div style={{fontSize:10, color:"#999", textTransform:"uppercase", marginTop: 3}}>{props.data.country_title}</div>
        </div>
      </div>
    </a>
  )
}