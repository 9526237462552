import { useEffect, useState } from "react"
import styles from './speedtest.module.css'

const Speedtest = (props) => {

	const [stage, setStage] = useState(1);
	const [progress, setProgress] = useState(0);
	
  useEffect(() => {
    const handler = event => {
			if (event.origin === "https://speed.lonelydesk.com") {
				let data = JSON.parse(event.data);
				setProgress(parseFloat(stage === 1 ? data.dlProgress : data.ulProgress) * 100.0);
				if (data.testState < 3 && stage !== 1) { setStage(1) }
				if (data.testState === 3 && stage < 2) { setStage(2) }
				if (data.testState === 4 && stage < 3) {
					setStage(3);
          props.onMeasurementComplete(data);
				}	
			}
    }
    window.addEventListener("message", handler)
    // clean up
    return () => window.removeEventListener("message", handler)
  }, [props, stage]) // empty array => run only once

	return (
		<div className={styles.testPanelContainer}>
			{
				(stage !== 3) &&
				<iframe className={styles.speedtestFrame} id="testing-iframe" scrolling="no" src="https://speed.lonelydesk.com/embed" width="220" height="138" frameBorder="0"></iframe>
			}
			<div className={styles.statusLabel}>
				{
					(stage === 1) && <span>Please wait a few seconds,<br/>we are estimating your download speed...</span>
				}
				{
					(stage === 2) && <span>Perfect, now we're going to check<br/>your upload capabilities...</span>
				}
				{
					(stage === 3) && <span>Great! That's it for the network test. Thanks!</span>
				}
			</div>
			{ 
			(stage !== 3) &&
			<div className={styles.progressContainer}>
				<div className={styles.progressContainerProgress} style={{width:`${progress}%`}}></div>
			</div>
			}
		</div>
	)

};

export default Speedtest;
