import { useEffect, useState } from 'react'
import Loading from '../../components/loading'
import LocationRow from '../../components/location_row'
import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title'
import { Networking } from '../../helpers/networking'

export default function Liked() {
  const [locations, setLocations] = useState([])
  const [loading, setLoading] = useState(true)

  const loadLikedLocations = async () => {
    const results = await Networking.likedLocations()
    setLocations(results)
    setLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      await loadLikedLocations()
    }

    fetchData()
  }, [])

  if (loading) {
    return (
      <PageContainer>
        <Loading page />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <PageTitle title="Your favorite locations" />
      {locations.length === 0 && (
        <div style={{ textAlign: 'left', width: '100%' }}>
          You haven't liked any locations yet. You can add some by clicking the heart on top of each
          location.
          <br />
          <br />
          This can be super useful if you are preparing for a trip to a specific location.
          <br />
          <br />
          <a href="/">Browse some cities</a>
        </div>
      )}
      {locations.map((location, i) => {
        return <LocationRow key={`location_${i}`} data={location} />
      })}
    </PageContainer>
  )
}
