import { useState, useEffect } from 'react'

import { Networking } from '../../helpers/networking';

import Button from '../../components/button';
import InputWithLabel from '../../components/input_with_label';
import LocationFinder from '../../components/location_finder';
import LocationTypePicker from '../../components/location_type_picker';
import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title';

import styles from './index.module.css';

export default function AddPlacePage() {

  const [locationTitle, setLocationTitle] = useState("");
  const [cityId, setCityId] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [locationTypeId, setLocationTypeId] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [locationTypes, setLocationTypes] = useState([]);

  useEffect(() => {
    Networking.fetchSupportedTypes()
    .then((response) => {
      setLocationTypes(response);
    })
  }, [])

  useEffect(() => {
    var valid = true;
    try {
      if (!cityId || cityId.length!==36) valid = false;
      if (!countryId|| cityId.length!==36) valid = false;
      if (!locationTitle) valid = false;
      if (locationTitle.length < 2) valid = false;
    } catch (e) { }
    setValidForm(valid);
  }, [cityId, countryId, locationTitle]);

  return (
    <PageContainer>
      <div style={{width:"100%"}}>
        <PageTitle title="I discovered a new place"/>
        <div className={styles.formContainer}>

          <InputWithLabel
            minWidth="full"
            label={"What's the name of this place?"}
            placeholder="Joe's Coffee"
            value={locationTitle}
            onChange={(data) => {
              setLocationTitle(data);
              //searchForSimilarLocations(data);  
            }}
          />
          
          <LocationFinder onChange={(city, country) => { setCityId(city); setCountryId(country) }}/>
          <LocationTypePicker types={locationTypes} locationType={locationTypeId} onChange={(t) => { setLocationTypeId(t) }}/>

          <div style={{marginBottom:40, marginTop:40}}>
            That's all we need for now! You can add a few more details about this place on the next page, if you want.
          </div>

          <Button size="large" disabled={!validForm} title={`Continue`} onClick={()=>{
            Networking.addLocation({
              city_uuid: cityId,
              country_uuid: countryId,
              title: locationTitle,
              type: locationTypeId
            }).then((data) => {
              if (data.status) {
                document.location.href = `/edit/${data.location_uuid}`;
              } else {
                document.location.href = `?error=unknown`;
              }
            });
          }}/>

        </div>
      </div>
    </PageContainer>
  )
}