import MenuBar from './menu_bar'
import InnerPageContainer from './inner_page_container'
import styles from './page_container.module.css'

export default function PageContainer(props) {
  var showMenu = true
  if (typeof props.hideMenu !== 'undefined' && props.hideMenu === true) {
    showMenu = false
  }
  return (
    <div className={styles.container}>
      {showMenu && <MenuBar />}
      <div className={styles.noMobile}>
        Apologies, we're not fully optimized for mobile yet. Early days.
      </div>

      <main
        className={styles.mainContainer}
        style={{ paddingTop: props.noPaddingOnTop || false ? 0 : 120 }}>
        <InnerPageContainer fullWidth={props.fullWidth || false}>
          {props.children}
        </InnerPageContainer>
      </main>

      <footer className={styles.footer}>Created on the road, for the road.</footer>
    </div>
  )
}
