import styles from './location_feature.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function LocationFeature(props) {
  if (props.icon) {
    return (
      <div className={styles.feature}>
        <FontAwesomeIcon icon={props.icon} color={props.color || "#ffffff"} style={{marginRight:5}}/> {props.title}
      </div>
    )  
  }
  return (
    <div className={styles.feature}>
      {props.title}
    </div>
  )
}