import React from 'react'
import PropTypes from 'prop-types'
import styles from './loading.module.css'

export default function Loading(props) {

  let color = "rgb(207, 85, 3)";
  let size = props.size || 80;
  let className = "";
  let style = "";

  const circles = [...Array(4)].map((_, index) => {
    return (
      <div
        key={index}
        style={{
          borderColor: `${color} transparent transparent transparent`,
          width: size * 0.8,
          height: size * 0.8,
          margin: size * 0.1,
          borderWidth: size * 0.1,
        }}
      ></div>
    )
  })

  if (props.page) {
    return (
      <div style={{textAlign: "center", marginTop: "40vh"}}>
        <div className={`${styles['lds-ring']} ${className}`} style={{ width: size, height: size, ...style }}>
          {circles}
        </div>
      </div>
    )  
  }

  return (
    <div className={`${styles['lds-ring']} ${className}`} style={{ width: size, height: size, ...style }}>
      {circles}
    </div>
  )
}

Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
}

Loading.defaultProps = {
  color: '#7f58af',
  size: 80,
  className: '',
  style: {},
}