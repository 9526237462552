import { useNavigate } from 'react-router-dom';
import styles from './tab_menu.module.css'

export default function TabMenu(props) {
  const navigate = useNavigate();
  return (
    <div style={{display: "flex", width: "100%", marginBottom:30}}>
      {
        props.tabs.map((tab,i) => {
          return (
            <div 
              key={`tab_${i}`}
              className={`${styles.tabItem} ${tab.isActive ? styles.tabItemActive : ""}`} 
              onClick={() => { navigate(tab.link) }}
            >{tab.title}</div>
          )
        })
      }
    </div>
  )
}