import { useState } from 'react'

import { Networking } from '../../helpers/networking'

import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title'
import InputWithLabel from '../../components/input_with_label'
import Button from '../../components/button'

import styles from './index.module.css'

export default function RegisterPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [pleaseVerify, setPleaseVerify] = useState(false)

  const doRegister = () => {
    Networking.accountRegister(email, password).then((result) => {
      if (result) {
        setPleaseVerify(true)
      }
    })
  }
  if (pleaseVerify) {
    return (
      <PageContainer>
        <div className={styles.registrationPanel} style={{ paddingTop: 40 }}>
          <strong>Thanks!</strong> <br />
          <br />
          Please check your e-mails now and click the link to verify your address.
          <br />
          <br />
          You can log in after you've confirmed it.
        </div>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <div className={styles.registrationPanel}>
        <PageTitle
          isSubtitle={true}
          withUnderline={true}
          centered={true}
          title={'Join our Community'}
        />
        <div style={{ fontSize: 18, lineHeight: '22px', textAlign: 'center' }}>
          You can sign up and access most of our community resources <strong>for free</strong>.
          <br />
          <br />
          <span style={{ fontSize: 13, lineHeight: '18px' }}>
            A selection of our content is limited to contributing members, so depending on how you
            support the community, access to content may vary.
          </span>
        </div>
        <div style={{ marginTop: 40 }}>
          <InputWithLabel
            label={`E-mail`}
            required={true}
            minWidth={`full`}
            onChange={(t) => {
              setEmail(t)
            }}
          />
        </div>
        <div>
          <InputWithLabel
            label={`Password`}
            required={true}
            minWidth={`full`}
            onChange={(t) => {
              setPassword(t)
            }}
            type={`password`}
          />
        </div>
        <div></div>
        <div style={{ fontSize: 12, marginBottom: 20 }}>
          By signing up, I acknowledge that I have read and agree to the{' '}
          <a target="_blank" href="/terms/privacy.html">
            privacy policy
          </a>{' '}
          and{' '}
          <a target="_blank" href="/terms/service.html">
            terms and conditions
          </a>
          .
        </div>
        <div>
          <Button title={`Sign up`} onClick={doRegister} />
        </div>
      </div>
    </PageContainer>
  )
}
