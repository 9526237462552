import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Networking } from '../../helpers/networking'

import PageContainer from '../../components/page_container'
import PageTitle from '../../components/page_title'
import InputWithLabel from '../../components/input_with_label'
import Button from '../../components/button'
import Loading from '../../components/loading'

import styles from './index.module.css'


export default function LoginPage() {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifiedReturn, setVerifiedReturn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const doLogin = () => {
    setLoading(true);
    setLoginError(false);
    Networking.cognitoLogin(email, password)      
    .then((result) => {
      if (result.status) {
        navigate("/");
      } else if (!result.status && result.error === "not_authorized") {        
        setLoginError("Hmm, we were unable to sign you in for some reason. Please double-check your password and try again. Have you verified your e-mail address?");
      } else if (!result.status && result.error === "confirm_email") {
        setLoginError("You need to confirm your e-mail address first. Check if you've received an e-mail and click on the link inside of that e-mail to proceed.");
      } else {
        setLoginError("Something went wrong while connecting our authentication server. Can you try again in a short while? Sorry about that.")
      }
    }).catch((e) => { console.log("Login error: ", e) }).finally(()=>{ setLoading(false); })    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      doLogin();
    }
  }

  if (window.location.search.indexOf("?verified")>=0) {
    if (!verifiedReturn) {
      setVerifiedReturn(true);
    }
  }

  return (
    <PageContainer>
      <div className={styles.loginPanel}>
      <PageTitle isSubtitle={true} withUnderline={true} title={"My LonelyDesk"}/>
        {
          verifiedReturn && !loginError &&
          <div style={{fontSize: 18, marginBottom:15, borderBottom: "1px solid #777", paddingTop: 10, paddingBottom: 10}}>
            Thanks for verifying your e-mail address! You can sign in now.
          </div>
        }
        {
          loginError && 
          <div style={{fontSize: 15, marginBottom:15, borderBottom: "1px solid #777", paddingTop: 10, paddingBottom: 10, color: "#ff0000"}}>
            {loginError}
          </div>
        }        
        <div style={{paddingTop:20}}>
          <InputWithLabel label={`E-mail`} minWidth={`full`} onChange={(t) => { setEmail(t) }} onKeyDown={handleKeyDown}/>
        </div>
        <div>
          <InputWithLabel label={`Password`} minWidth={`full`} onChange={(t => { setPassword(t) } )} type={`password`} onKeyDown={handleKeyDown}/>
        </div>
        <div style={{marginBottom:40, fontSize: 14, lineHeight: "20px"}}>
          If you don't have an account yet, you can always <Link to="/register">create one here for free.</Link>
        </div>
        <div>
          { isLoading && <Loading size={20}/>}
          { !isLoading && <Button title={`Sign in`} onClick={doLogin}/> }
        </div>

      </div>
    </PageContainer>
  )
}