import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import Amplify from 'aws-amplify'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en-GB.json'

import './globals.css';
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css'
import '../node_modules/leaflet/dist/leaflet.css'

import awsConfig from "./aws-configuration";
import App from './App';

Amplify.configure({...awsConfig, ssr: true});

TimeAgo.setDefaultLocale(en.locale);
TimeAgo.addLocale(en);

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);